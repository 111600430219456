import React from 'react'
// import topleftheaderimage from '../assets/images/logotopleft.png'
// import toprightheaderimage from '../assets/images/logotopright.png'
function Header() {
  return (
    <div className='bg-white px-2 px-lg-5 py-3  d-flex justify-content-between'>
        {/* <img src={toprightheaderimage} width={150} height={50}/>
        <img src={topleftheaderimage} width={150} height={50}/> */}
    </div>
  )
}

export default Header
