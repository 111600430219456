import React from 'react'
import nutrition from '../assets/images/nutrition.png'

function Footer() {
  return (
    <div className='p-5 flout-end position-absolute bottom-0'>
        {/* <img className='w-50 h-50 w-md-100 h-md-100' src={nutrition} width={350} height={100}/> */}
    </div>
  )
}

export default Footer
